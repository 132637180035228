<!-- 礼品领取 -->
<template>
  <div :class="['testDriveGift', (params.receiveType == 2) ? 'testDriveGift-pb' : '']">
    <van-form ref="form" error-message-align="right" @submit.native.prevent>
      <div :class="['status', statusEnum === 'RECEIVED' ? 'active' : '']">{{ receiveStatusInfo[statusEnum] }}</div>
      <div v-if="['NOT_SEND', 'NOT_RECEIVE'].includes(statusEnum)" class="common-block">
        <van-field
          v-model="params.receiveName"
          input-align="right"
          :label="$t('试驾礼品领取方式')"
          :right-icon="statusEnum === 'NOT_RECEIVE' ? '' : 'arrow'"
          readonly
          @click="selectField('receiveType', 'receiveName', false)">
        </van-field>
      </div>
      <!-- 未领取(选择方式领取) 已发未领/已领取(查看领取详情) -->
      <div v-if="['NOT_SEND', 'RECEIVED', 'NOT_RECEIVE'].includes(statusEnum) && params.receiveType" class="common-block">
        <!-- 线下领取 -->
        <template v-if="params.receiveType == 2">
          <van-field
            :label="$t('线下领取拍照')"
            :required="statusEnum === 'NOT_SEND' ? 'required' : '' "
            class="spacial-cell"
            :rules="[{required: true, message: $t('请上传线下领取照片')}]">
            <template #input>
              <van-uploader
                key="'offlineUrl'"
                v-model="fileList"
                class="upload"
                accept="image/*"
                :deletable="statusEnum === 'NOT_SEND'"
                :preview-options="{closeable: true}"
                :before-read="beforeRead"
                :after-read="(file) => afterRead(file)"
                :before-delete="(file) => beforeDelete(file)"
                :max-count="1"/>
              <p class="tip">{{ $t('请拍摄一张客户领取试驾礼品的照片') }}</p>
            </template>
          </van-field>
        </template>
        <!-- 线上领取 -->
        <template v-if="params.receiveType == 1">
          <!-- 未领取 -->
          <template v-if="['NOT_RECEIVE', 'NOT_SEND'].includes(statusEnum)">
            <Gift :statusEnum="'NOT_SEND'" @send="send"></Gift>
          </template>
          <!-- 已下单 -->
          <template v-else-if="statusEnum === 'RECEIVED'">
            <Gift :statusEnum="'RECEIVED'" :giftInfo="giftInfo"></Gift>
            <van-cell :title="$t('订单信息')" class="cell_t"/>
            <van-cell :title="$t('商城单号')" :value="giftInfo.giftOrderId || $t('无')" />
          </template>
        </template>
      </div>
      <div v-if="params.receiveType == 2 && statusEnum == 'NOT_SEND'" class="common-footer">
        <van-button class="btn submit" type="submit" @click="onSubmit">{{ $t('保存') }}</van-button>
      </div>
    </van-form>
    <van-popup v-model="popupShow" position="bottom">
      <div class="shareBox">
        <div v-for="(item, index) in shareTypeList" :key="index" class="share-i" @click="shareClick(item)">
          <img class="icon" :src="item.url" />
          <div>{{ item.txt }}</div>
        </div>
      </div>
    </van-popup>
    <!-- <van-dialog
      v-model="showDialog"
      :title="$t('修改礼品')"
      show-cancel-button
      :before-close="beforeClose"
      :confirm-button-text="$t('提交审核')"
      confirm-button-color="#B9921A">
      <div class="giftList">
        <div v-for="(item, index) in giftList" :key="index" class="gift_i" @click="checkGiftId = item.productId">
          <div class="name">{{ item.productName }}</div>
          <van-icon v-if="checkGiftId == item.productId" color="#B9921A" size="24" name="success" />
        </div>
      </div>
    </van-dialog> -->
  </div>
</template>
<script>
import commonSelector from '@/components/common-selector'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import Gift from './components/Gift.vue'
import loading from '@/utils/loading'
import testDriveServices from '@/services/testDrive'
import msgIcon from '@/images/icon_msg.svg'
import wechatIcon from '@/images/icon_wechat.svg'
import appIcon from '@/images/icon_app.svg'
import wxSDK from '@/scripts/wxsdk'
let vm
export default {
  components: {
    Gift
  },
  data() {
    return {
      msgIcon,
      appIcon,
      wechatIcon,
      id: '',
      customerMobile: '',
      seriesCode: '',
      statusEnum: '',
      receiveType: '',
      params: {
        receiveType: '',
        receiveName: ''
      },
      fileList: [],
      giftInfo: {}, // 礼品信息
      // showDialog: false,
      // giftList: [], // 礼物列表
      checkGiftId: '',
      receiveOnLine: false,
      popupShow: false,
      shareTypeList: [
        { txt: this.$t('短信'), url: msgIcon, type: 'msg' },
        { txt: this.$t('微信'), url: wechatIcon, type: 'wechat' },
      ]
    }
  },
  computed: {
    receiveStatusInfo() {
      return {
        'UNQUALIFIED': this.$t('无领取资格'),
        'NOT_SEND': this.$t('未发送'),
        'NOT_RECEIVE': this.$t('已发未领'),
        'RECEIVED': this.$t('已领取'),
        'NOT_LOTTERY': this.$t('未中奖'),
      }
    },
    cellOptions() {
      let arr = [
        { dictName: this.$t('线下领取'), dictCode: 2 },
      ]
      if (this.receiveOnLine) {
        arr.push({ dictName: this.$t('线上领取'), dictCode: 1 })
      }
      return arr
    }
  },
  watch: {
    'params.receiveType': {
      handler(val) {
        // this.fileList = []
      },
      immediate: true
    },
    statusEnum: {
      handler(val) {
        if (val === 'RECEIVED') {
          this.getGiftDetail()
        }
      },
      immediate: true
    },
    receiveType: {
      handler(val) {
        if (val) {
          this.params = {
            receiveType: val,
            receiveName: val === 1 ? this.$t('线上领取') : this.$t('线下领取')
          }
        }
      },
      immediate: true
    }
  },
  beforeCreate(){
    vm = this
  },
  mounted() {
    this._data = Object.assign(this._data, this.$route.query)
    this.testDriveConfig()
    // 等于已发未领的时候 领取方式默认选中线上领取 切不可修改
    if (this.statusEnum === 'NOT_RECEIVE') {
      this.params.receiveType = 1
      this.params.receiveName = this.$t('线上领取')
    }
  },
  methods: {
    // 获取试驾礼品领取方式数据
    async testDriveConfig() {
      const res = await testDriveServices.testDriveConfig({ configType:'2015001' })
      this.receiveOnLine = res.configContent?.receiveOnLine || false
    },
    async selectField(field, name, multiple = false) {
      if (this.statusEnum === 'NOT_RECEIVE') return false
      const _obj = {
        field,
        lastSelect: this.params.receiveType,
        multiple,
        cellOptions: this.cellOptions
      }
      commonSelector(_obj).then(res => {
        this.params[field] = res.dictCode
        if (name) this.params[name] = res.dictName
      })
    },
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (file.size > 10 * 1024 * 1024) {
        this.$toast.fail(this.$t('请上传不超过10MB的图片'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    // 线下领取提交
    onSubmit() {
      this.$refs.form.validate().then(async() => {
        const params = {
          appointId: this.$route.query.id,
          receivePhotoUrl: this.fileList[0].cosUrl,
          receiveType: this.params.receiveType,
        }
        loading.showLoading()
        const res = await testDriveServices.giftOfflineReceive(params)
        loading.hideLoading()
        this.$toast.success(this.$t('领取成功！'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 1500)
      })
    },
    // 线上领取发送
    async send() {
      this.popupShow = true
      // 1、暂时先不发短信 因为小程序 还未打通
      // loading.showLoading()
      // const res = await testDriveServices.giftSend({ appointId: this.$route.query.id })
      // loading.hideLoading()
      // this.$toast.success(this.$t('发送成功！'))
      // setTimeout(() => {
      //   this.$router.go(-1)
      // }, 1500)

      // 2、分享给外部联系人
      // const that = this
      // wx.invoke(
      //   'shareAppMessage', {
      //     title: this.$t('领取试驾礼'), // 分享标题
      //     desc: '尊敬的客户，感谢体验ID. 与众 ，请点击领取您的试驾礼品，期待与您的再次相会。', // 分享描述
      //     // link: `${window.origin}/receiveGift?appointmentid=${that.$route.query.id}`, // 分享链接
      //     link: `${process.env.VUE_APP_ENV === 'uat' ? 'https://uat-oneapp-web.idux-vw.com' : 'https://m.idux-vw.com'}/WXminiBridge?path=pages%2FofflineTestDriveReportH5%2FofflineTestDriveReportH5&query=appointmentid%3D${that.$route.query.id}`, // 分享链接
      //     imgUrl: `${location.origin}/bf56ddbb00d886cf6a3cdbe4b7ce845.png`, // 分享封面
      //   }, function (res) {
      //     if (res.err_msg == 'shareAppMessage:ok') {
      //     }
      //   }
      // )
    },
    async shareClick({ type }) {
      const contentJson = {
        title: this.$t('领取试驾礼'), // 分享标题
        desc: '尊敬的客户，感谢体验ID. 与众 ，请点击领取您的试驾礼品，期待与您的再次相会。', // 分享描述
        link: `${process.env.VUE_APP_ENV === 'uat' ? 'https://uat-oneapp-web.idux-vw.com' : 'https://m.idux-vw.com'}/WXminiBridge?path=pages%2FuniversalLinks%2FuniversalLinks&query=appointmentid%3D${this.$route.query.id}%26toPage%3DSCRMLink`, // 分享链接
        imgUrl: `${location.origin}/bf56ddbb00d886cf6a3cdbe4b7ce845.png`, // 分享封面
      }
      let params = {
        sendWay: '',
        driveReportUrl: `${window.location.origin}/test-drive-report?driveRecordId=${this.driveRecordId}`,
        driveRecordId: this.driveRecordId
      }
      switch (type) {
        case 'msg':
          loading.showLoading()
          await testDriveServices.giftSend({ appointId: this.$route.query.id })
          loading.hideLoading()
          this.$toast.success(this.$t('发送成功！'))
          this.popupShow = false
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
          break
        case 'app':
          // params.sendWay= 2
          // loading.showLoading()
          // await testDriveServices.reportSend(params)
          // this.$toast(this.$t('发送成功'))
          // this.popupShow = false
          // loading.hideLoading()
          break
        case 'wechat':
          wxSDK.shareAppMessage(contentJson)
          this.popupShow = false
          break
        default:
          break
      }
    },
    // 修改礼品
    // async modifyGift() {
    //   loading.showLoading()
    //   const res = await testDriveServices.getGiftList()
    //   loading.hideLoading()
    //   this.giftList = res
    //   this.showDialog = true
    // },
    // async beforeClose(action, done) {
    //   var that = this
    //   if (action === 'confirm') {
    //     if (!that.checkGiftId) {
    //       this.$toast('请选择要修改的礼品！')
    //       done(false)
    //       return false
    //     }
    //     this.$toast(this.$t('开发中。。。'))
    //     done(false)
    //   } else {
    //     that.checkGiftId = ''
    //     done()
    //   }
    // },
    // 获取礼品详情
    async getGiftDetail() {
      loading.showLoading()
      const res = await testDriveServices.getGiftDetail({ appointId: this.$route.query.id })
      if (res.receiveType == 2 && res.receivePhotoUrl) {
        this.fileList.push({ url: res.receivePhotoUrl })
      }
      loading.hideLoading()
      this.giftInfo = res
    }
  }
}
</script>
<style lang="less" scoped>
.testDriveGift{
  padding: 16px;
  &.testDriveGift-pb{
    padding-bottom: 96px;
  }
  .status {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 12px;
    &.active{
      color: @yellow-text;
    }
  }
  .common-block{
    padding: 12px;
    &:not(:last-of-type) {
      margin-bottom: 16px
    }
    /deep/ .van-cell{
      padding: 10px 0;
      .van-field__control{
        color: #969799 !important;
        text-align: right;
      }
      &::after{
        left: 0;
        right: 0
      }
      &.spacial-cell{
        display: flex;
        flex-direction: column;
        .van-field__body{
          padding-top: 10px;
          .van-field__control{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            .tip{
              color: rgba(13,23,26,0.45);
              font-size: 12px;
            }
          }
        }
      }
      &.cell_t{
        font-size: 15px;
        font-weight: 600;
      }
      &.cell_address::after{
        display: none;
      }
    }
  }
  /deep/ .giftList{
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-height: 400px;
    overflow-y: auto;
    .gift_i{
      display: flex;
      align-items: center;
      height: 36px;
      line-height: 36px;
      justify-content: space-between;
      border-bottom: 1px solid #ebedf0;
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}
.shareBox{
  display: flex;
  padding: 20px 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  justify-content: space-between;
  .share-i{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    .icon{
      width: 25px;
      height: 25px;
      margin-bottom: 12px;
    }
  }
}
</style>