import CryptoJS from 'crypto'
import { v4 as uuidv4 } from 'uuid'
const client_secret = process.env.VUE_APP_THIRD_SYSTEM_SERVICE_CLIENT_SECRET || '3c3lvltuB68rFc*3aLuyogWyK'
const client_id = process.env.VUE_APP_THIRD_SYSTEM_SERVICE_CLIENT_ID || '7ShtpB$m#qXVN@E8cUSvQHyk'
export function genSignHeaders(data = {}) {
  const { 
    clientId = client_id,
    clientSecret = client_secret,
    method,
    uri,
    // host = location.host == 'localhost' ? 'localhost' : 'scrm-third-system-gateway/qw',
    host = location.host == 'localhost' ? 'localhost' : 'scrm-third-system-gateway',
    requestBody = {} 
  } = data
  let date = getGMTString()
  let nonce = uuidv4()
  let signature = sign(clientId, clientSecret, method, uri, host, date, nonce)
  let headers = {
    'x-client-id': clientId,
    'uri': uri,
    'x-date': date,
    'x-nonce': nonce,
    'X-Signature': signature,
  }
  if (method === 'POST') {
    let bodySignature = digest(JSON.stringify(requestBody), clientSecret)
    headers['X-Digest'] = bodySignature
  }
  return headers
}

function sign(clientId, clientSecret, method, uri, host, date, nonce) {
  let signString = `${method}${uri}\nhost=${host}\nx-client-id=${clientId}\nx-date=${date}\nx-nonce=${nonce}`
  let signature = digest(signString, clientSecret)
  return signature
}

function getGMTString() {
  const now = new Date()
  const gmtString = now.toUTCString()
  return gmtString
}

function digest(body, clientSecret) {
  let mac = CryptoJS.createHmac('sha256', clientSecret)
  let byteVal = mac.update(body).digest()
  return byteVal.toString('base64')
}
