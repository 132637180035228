<!-- 试驾礼品具体信息 -->
<template>
  <div class="gift_info">
    <van-cell v-if="statusEnum === 'NOT_SEND'" :title="$t('试驾礼品信息')" class="cell_t">
      <template #default>
        <span style="color:#B9921A; font-size: 12px; font-weight: normal;" @click="send">{{ $t('点击发送') }}</span>
      </template>
    </van-cell>
    <van-cell :title="$t('试驾礼品')">
      <template #default>
        <div v-if="statusEnum === 'NOT_SEND'">{{ $t('无') }}</div>
        <div v-if="statusEnum === 'RECEIVED'">
          <span>{{ giftInfo.productName }}</span>
        </div>
      </template>
    </van-cell>
    <van-cell :title="$t('收货信息')" class="cell_t" />
    <van-cell :title="$t('收货人')" :value="giftInfo.consigneeName || $t('无')" />
    <van-cell :title="$t('手机号码')" :value="giftInfo.consigneePhone || $t('无')" />
    <van-cell :title="$t('收货地址')" class="cell_address" />
    <van-field type="textarea" row="2" :maxlength="50" disabled :value="addressInfo" />
  </div>
</template>
<script>
export default {
  props: {
    statusEnum: {
      type: String,
      default: () => 'NOT_RECEIVE'
    },
    giftInfo: { // 礼品信息
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  computed: {
    addressInfo() {
      let str = ''
      const { province='', city='', region='', consigneeAddress='' } = this.giftInfo
      str = `${province}${city}${region}${consigneeAddress}`
      return str || this.$t('暂无')
    }
  },
  async mounted() {
  },
  
  methods: {
    modifyGift() {
      this.$emit('modifyGift')
    },
    send() {
      this.$emit('send')
    }
  }
}
</script>
<style lang="less"></style>